import ProGalleryStore from './BlueprintsProGalleryStore';
import {
  experimentsWrapper,
  translationUtils,
} from '@wix/photography-client-lib';

import directFullscreenHelper from './helpers/directFullscreenHelper';
import { Utils } from '../utils/workerUtils';

export default class CommonBlueprintsViewerScript {
  constructor(
    context,
    controllerConfigs,
    APP_DEFINITION_ID,
    GALLERY_WIDGET_ID,
    isArtStore = false,
  ) {
    this.context = context.getContext();
    this.pgStore = null;
    this.isArtStore = isArtStore;
    this.controllerConfigs = controllerConfigs;
    this.wixCodeApi = controllerConfigs.wixCodeApi;
    this.sentryReporter = context.getSentry();
    this.initPgStore(APP_DEFINITION_ID, GALLERY_WIDGET_ID);
    this.setWarmupDataIfNeeded();

    this.sentryReport = this.sentryReport.bind(this);
  }

  setContext(context) {
    this.context = context;
  }

  setSentryReporter(sentryReporter) {
    this.sentryReporter = sentryReporter;
  }

  getPgStore() {
    return this.pgStore;
  }

  getClickedIdx() {
    return this.getPgStore().clickedIdx;
  }
  getTotalItemsCount() {
    return this.getPgStore().getTotalItemsCount();
  }

  getbaseUrls() {
    return this.getContext().baseUrls;
  }

  getWarmupData() {
    return {
      items: this.getItems(),
      totalItemsCount: this.getPgStore().totalItemsCount,
      experiments: experimentsWrapper.experiments,
    };
  }

  getDirectFullscreenItem() {
    return this.getPgStore().getDirectFullscreenItem();
  }
  getTestType() {
    return this.getPgStore().getTestType();
  }

  setMetaTagsInSSRIfNeeded() {
    const directFullscreenItem = this.getDirectFullscreenItem();
    if (this.isSSR() && directFullscreenItem && directFullscreenItem.itemId) {
      const pgStore = this.getPgStore();
      const wixCodeApi = this.wixCodeApi;
      const currentDirectFullscreenUrl = ProGalleryStore.getCurrentDirectFullscreenUrl(
        wixCodeApi,
      );
      const pageUrl = ProGalleryStore.getPageUrl(wixCodeApi);
      pgStore.setMetaTagsInSSR(
        directFullscreenItem,
        pageUrl,
        currentDirectFullscreenUrl,
        wixCodeApi,
      );
    }
  }

  isInSEO() {
    const wixCodeApi = this.wixCodeApi;
    return (
      typeof wixCodeApi.seo.isInSEO === 'function' && !!wixCodeApi.seo.isInSEO()
    );
  }

  getQueryParams() {
    return this.wixCodeApi.location.query;
  }

  getLocaleFromContext() {
    const { scopedGlobalSdkApis } = this.context;
    return CommonBlueprintsViewerScript.getLocaleFromScopedGlobalSdkApis(
      scopedGlobalSdkApis,
    );
  }

  getCommonGalleryProps(isStore) {
    const pgStore = this.getPgStore();
    const wixCodeApi = this.wixCodeApi;
    const siteUrl = ProGalleryStore.getSiteUrl(wixCodeApi);
    const fullscreenEndPoint = isStore
      ? '/fullscreen-store-page'
      : '/fullscreen-page';
    const baseUrl = isStore
      ? this.context.scopedGlobalSdkApis.location.baseUrl
      : Utils.getBaseUrl(
          wixCodeApi,
          Utils.parseViewMode(wixCodeApi.window.viewMode),
        );
    return {
      clientSetProps: !this.isSSR(), //
      propFromSetPropsIndicator: true, // if this prop exists in the component (commonGalleryWrapper), it means that setProps was already executed
      onNavigate: (url) => wixCodeApi.location.to(url),
      scrollTo: wixCodeApi.window.scrollTo,
      instance: this.getControllerConfig().appParams.instance,
      appDefinitionId: this.getControllerConfig().appParams.appDefinitionId,
      visitorId: this.getControllerConfig().platformAPIs.bi.visitorId,
      queryParams: wixCodeApi.location.query,
      viewMode: wixCodeApi.window.viewMode,
      baseUrl: baseUrl,
      siteUrl: siteUrl,
      pageUrl: ProGalleryStore.getPageUrl(wixCodeApi),
      fullscreenUrl: siteUrl + fullscreenEndPoint,
      instanceId: this.getInstanceId(),
      reportBiLog: pgStore.reportBiLog,
      totalItemsCount: pgStore.totalItemsCount,
      gallerySettings: pgStore.settings,
      dateCreated: pgStore.dateCreated,
      galleryId: pgStore.galleryId,
      translations: translationUtils.translations,
      experiments: experimentsWrapper.experiments,
      setHeight: this.getSetHeightFunc(),
      getMoreItems: (from) => this.getMoreItems(from),
      onDimensionsReady: (dimensions) => this.handleDimensions(dimensions),
      onAccessibilityChanged: (isAccessible) =>
        this.handleAccessibilityChange(isAccessible),
      onItemDimensionsMeasure: (itemDimensions) =>
        this.handleNewItemDimensions(itemDimensions),
      appLoadStartedReported: pgStore.isAppLoadStartReported,
      tryToReportAppLoaded: pgStore.tryToReportAppLoaded,
      sentryReport: this.sentryReport,
      locale: this.getLocaleFromContext(),
    };
  }

  getTryToReportAppLoaded() {
    return this.getPgStore().tryToReportAppLoaded;
  }

  getBlueprintPromise() {
    return this.getPgStore().loadInitialBlueprint(this.isSSR());
  }

  getDirectFullscreenPromise() {
    const { compId } = this.getControllerConfig();
    return directFullscreenHelper.getDirectFullscreenPromise(
      compId,
      this.getQueryParams(),
      this.getPgStore(),
      this.isSSR(),
    );
  }

  getViewPortPromise() {
    const { compId, wixCodeApi } = this.getControllerConfig();
    return (
      (this.isSSR() && wixCodeApi.window.getComponentViewportState(compId)) ||
      Promise.resolve({ in: true })
    );
  }

  initPgStore(APP_DEFINITION_ID, GALLERY_WIDGET_ID) {
    const controllerData = this.getControllerConfig();
    const {
      appParams: { instanceId, instance },
      config: { externalId, style },
      compId,
      wixCodeApi,
    } = controllerData;
    const { msid, platformServices, scopedGlobalSdkApis } = this.getContext();
    this.pgStore = new ProGalleryStore(
      {
        externalId,
        compId,
        instanceId,
        instance,
        msid,
        styles: style.styleParams,
      },
      wixCodeApi,
      platformServices,
      scopedGlobalSdkApis,
      APP_DEFINITION_ID,
      GALLERY_WIDGET_ID,
      this.isArtStore,
      this.setDirectfullscreenItem.bind(this),
      this.setBlueprint.bind(this),
    );
  }

  getContext() {
    return this.context;
  }

  tryToReportAppLoaded() {
    this.getPgStore().tryToReportAppLoaded();
  }

  getSetHeightFunc() {
    const { setProps } = this.getControllerConfig();
    return (height) => {
      setProps({
        dimensions: {
          height,
        },
      });
    };
  }

  getSetMetaTagsFunc() {
    return (itemData) => {
      this.getPgStore().setMetaTags(itemData, this.wixCodeApi);
    };
  }

  getOnLinkNavigationFunc() {
    return (item) => this.getPgStore().navigateToLink(item);
  }

  getOnItemClickedFunc(galleryWixCodeApi) {
    return (itemData, event) => {
      if (galleryWixCodeApi) {
        galleryWixCodeApi.onItemClicked(itemData, event);
      }
    };
  }

  handleDimensions(dimensions) {
    const proGalleryStore = this.getPgStore();
    proGalleryStore.handleDimensions(dimensions);
  }

  handleAccessibilityChange(isAccessible) {
    const proGalleryStore = this.getPgStore();
    proGalleryStore.handleAccessibilityChange(isAccessible);
  }
  handleNewItemDimensions(itemDimensions) {
    const proGalleryStore = this.getPgStore();
    proGalleryStore.handleNewItemDimensions(itemDimensions);
  }

  getMoreItems(from) {
    const proGalleryStore = this.getPgStore();
    proGalleryStore.getMoreItems(from);
  }

  setBlueprint() {
    const proGalleryStore = this.getPgStore();
    const { setProps } = this.getControllerConfig();
    const blueprint = proGalleryStore.getBlueprint();
    setProps({
      ...blueprint,
    });
  }

  setDirectfullscreenItem() {
    const proGalleryStore = this.getPgStore();
    const { setProps } = this.getControllerConfig();
    setProps({
      directFullscreenItem: proGalleryStore.getDirectFullscreenItem(),
    });
  }

  getControllerConfig() {
    return this.controllerConfigs;
  }

  setWarmupDataIfNeeded() {
    const { warmupData } = this.getControllerConfig();
    if (warmupData) {
      const proGalleryStore = this.getPgStore();
      proGalleryStore.setItems(warmupData.items);
      proGalleryStore.totalItemsCount = warmupData.totalItemsCount;
      experimentsWrapper.setExperiments(warmupData.experiments);
    }
  }

  getSetNewItemsFunc() {
    const pgStore = this.getPgStore();
    const { setProps } = this.getControllerConfig();
    const setNewItems = (items) => {
      const directFullscreenItem = directFullscreenHelper.getDirectFullscreenItemFromItemsList(
        this.getQueryParams(),
        items,
      );
      directFullscreenItem &&
        pgStore.setDirectFullscreenItem(directFullscreenItem);

      const _items = items.filter((item) => !!item.mediaUrl);

      pgStore.allWixCodeItems = _items;
      pgStore.items = _items.slice(0, pgStore.INITIAL_ITEMS_BATCH_SIZE);

      setProps({
        totalItemsCount: pgStore.totalItemsCount,
      });
    };
    return setNewItems;
  }

  handleNewStyleParams(manualStyleParams) {
    this.getPgStore().handleManualStyleParams(manualStyleParams);
  }

  setClickedIdx(clickedIdx) {
    const { setProps } = this.getControllerConfig();
    setProps({ clickedIdx });
  }

  isValidWidgetId(type) {
    const WIDGET_ARRAY = this.WIDGET_ID_ARRAY;
    return WIDGET_ARRAY.includes(type);
  }

  sentryReport(error) {
    this.sentryReporter.report(error);
  }

  getItems() {
    return this.getPgStore().getItems();
  }

  getSettings() {
    return this.getPgStore().settings;
  }

  getWatermark() {
    const settings = this.getSettings();
    if (!settings) {
      return;
    }
    if (typeof settings === 'object') {
      return settings.watermark;
    }
    return JSON.parse(settings).watermark;
  }

  getInstanceId() {
    return this.getControllerConfig().appParams.instanceId;
  }

  getSSRWorkerLog() {
    return this.getPgStore().getSSRWorkerLog();
  }

  static getLocaleFromScopedGlobalSdkApis(scopedGlobalSdkApis) {
    if (
      scopedGlobalSdkApis &&
      scopedGlobalSdkApis.site &&
      scopedGlobalSdkApis.site.language
    ) {
      return scopedGlobalSdkApis.site.language;
    } else {
      return 'en';
    }
  }

  static setTranslationsAndExperiments(scopedGlobalSdkApis, sentryReporter) {
    try {
      const locale = CommonBlueprintsViewerScript.getLocaleFromScopedGlobalSdkApis(
        scopedGlobalSdkApis,
      );
      const translationsPromise = ProGalleryStore.getTranslations(locale);
      Utils.getExperimentsAndInitWrapper()(
        scopedGlobalSdkApis,
        sentryReporter && sentryReporter.report,
      );
      translationsPromise
        .then((translationsRaw) =>
          translationUtils.setTranslations(translationsRaw),
        )
        .catch((e) => {
          if (sentryReporter && sentryReporter.report) {
            sentryReporter.report(e);
          }
          console.error('Waiting for translationsPromise failed', e);
        });
    } catch (e) {
      if (sentryReporter && sentryReporter.report) {
        sentryReporter.report(e);
      }
      console.error('Getting page init data failed', e);
    }
  }

  static getInitAppForPageFunc(context) {
    return (
      initAppParam,
      platformUtilitiesApi,
      scopedGlobalSdkApis,
      platformServices,
    ) => {
      context.setContext({
        ...initAppParam,
        platformServices,
        scopedGlobalSdkApis,
        platformUtilitiesApi,
      });
      context.initSentry(initAppParam, scopedGlobalSdkApis, platformServices);
      CommonBlueprintsViewerScript.setTranslationsAndExperiments(
        scopedGlobalSdkApis,
        context.getSentry(),
      );
    };
  }

  isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  static getCreateControllers(controllerFunc, sentryReporter) {
    return (controllerConfigs) => {
      try {
        const activeWidgets = controllerConfigs.map((config) => {
          const { type, compId, setProps } = config;
          return { type, compId, setProps };
        });
        return controllerConfigs.map(function (config) {
          return Promise.resolve(controllerFunc({ ...config, activeWidgets }));
        });
      } catch (e) {
        if (sentryReporter && sentryReporter.report) {
          sentryReporter.report(e);
        }
      }
    };
  }
}
